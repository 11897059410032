'use client';

import { PropsWithChildren, useMemo } from 'react';
import mediaQuery from 'css-mediaquery';
import { CssBaseline, Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from '@mui/material';
import { AppCacheProvider, EmotionCacheProviderProps } from '@mui/material-nextjs/v14-pagesRouter';
import { deepmerge } from '@mui/utils';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { spaThemeOptions } from '../../utils/spaTheme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#typescript
 */
export const PagesRouterThemeProvider = ({
  children,
  emotionCache,
  isUserAgentDeviceMobile,
  tenantAlias
}: PropsWithChildren<EmotionCacheProviderProps & {
  isUserAgentDeviceMobile: boolean;
  tenantAlias: TenantAlias;
}>) => {
  /**
   * Experimental solution to provide correct mediaQueries based on device type extracted from user-agent.
   * This is useful to prevent flashes and big differences between the SSR and Client Side versions
   * @see https://dev.to/rakshitnayak/how-to-implement-server-side-rendering-for-material-uis-media-queries-in-nextjs-to-avoid-flash-jpi
   * @see https://mui.com/material-ui/react-use-media-query/#server-side-rendering
   */
  const themeWithMatchMedia = useMemo(() => {
    const options = deepmerge(spaThemeOptions(tenantAlias), {
      components: {
        MuiUseMediaQuery: {
          defaultProps: {
            ssrMatchMedia: (query: string) => ({
              matches: mediaQuery.match(query, {
                // The estimated CSS width of the browser based on the device user-agent
                width: isUserAgentDeviceMobile ? '0px' : '1200px'
              })
            })
          }
        }
      }
    });
    return extendTheme(options);
  }, [isUserAgentDeviceMobile, tenantAlias]);
  return <AppCacheProvider emotionCache={emotionCache} data-sentry-element="AppCacheProvider" data-sentry-component="PagesRouterThemeProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
      <CssVarsProvider theme={themeWithMatchMedia} data-sentry-element="CssVarsProvider" data-sentry-source-file="PagesRouterThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="PagesRouterThemeProvider.tsx" />
        {children}
      </CssVarsProvider>
    </AppCacheProvider>;
};