'use client';

import { createContext, ReactNode, useState } from 'react';
import noop from 'lodash/noop';
import { SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
type ContextType = {
  setActiveDrawer: (component: ReactNode, drawerPaperSx?: SystemStyleObject, gridContainerSx?: SxProps) => void;
  activeDrawer: ReactNode | undefined;
  isOpen: boolean;
  drawerPaperSx: SystemStyleObject;
  gridContainerSx: SxProps;
  hideDrawer: () => void;
};
export const RightDrawerContext = createContext<ContextType>({
  isOpen: false,
  hideDrawer: noop,
  setActiveDrawer: noop,
  drawerPaperSx: {},
  gridContainerSx: {},
  activeDrawer: null
});
type Props = {
  children: ReactNode;
};
export const RightDrawerContextProvider = ({
  children
}: Props) => {
  const [activeDrawerState, setActiveDrawerState] = useState<ReactNode>(null);
  const [drawerPaperSx, setDrawerPaperSx] = useState<SystemStyleObject>({});
  const [gridContainerSx, setGridContainerSx] = useState<SxProps>({});
  const [isOpen, setIsOpen] = useState(false);
  const hideDrawer = () => {
    setIsOpen(false);
  };
  function setActiveDrawer(activeDrawer: ReactNode, drawerPaperSxParam: SystemStyleObject = {}, gridContainerSxParam: SxProps = {}) {
    setActiveDrawerState(activeDrawer);
    setIsOpen(true);
    setDrawerPaperSx(drawerPaperSxParam);
    setGridContainerSx(gridContainerSxParam);
  }
  return <RightDrawerContext.Provider value={{
    setActiveDrawer,
    activeDrawer: activeDrawerState,
    isOpen,
    drawerPaperSx,
    gridContainerSx,
    hideDrawer
  }} data-sentry-element="unknown" data-sentry-component="RightDrawerContextProvider" data-sentry-source-file="RightDrawerContext.tsx">
      {children}
    </RightDrawerContext.Provider>;
};