import { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Error } from '../Error/Error';
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, 'componentDidCatch.error');
    console.error(errorInfo, 'componentDidCatch.errorInfo');
    Sentry.captureException(error, scope => {
      scope.setContext('react', errorInfo);
      return scope.setTag('handler', 'mainApp.ErrorBoundary');
    });
  }
  render() {
    if (this.state.hasError) {
      return <Error data-sentry-element="Error" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.jsx" />;
    }
    return this.props.children;
  }
}